import Vue from 'vue'
import VueRouter from 'vue-router'
import FloodTrackDashboard from '../components/pages/FloodTrack.vue'
import WaterTrackDashboard from '../components/pages/WaterTrack.vue'
import SewerOpsDashboard from '../components/pages/SewerOps.vue'
import WaterOpsDashboard from '../components/pages/WaterOps'
import IncidentTracking from '@/components/pages/IncidentTracking'
import AlertDashboard from '@/components/pages/AlertDashboard'
import DemoPage from '@/components/pages/DemoPage.vue'
import WaterQualityDashboard from '@/components/pages/WaterQuality'
import PermissionErrorPage from '@/components/pages/PermissionErrorPage'
import store from '../store'
import { DashboardNames } from '@/types/dashboardNameEnums'

Vue.use(VueRouter)

const dashboardToRole = {
	[DashboardNames.IncidentTracking]: 'EMS_INCIDENT_TRACKING',
	[DashboardNames.FloodTrack]: 'EMS_FLOODTRACK',
	[DashboardNames.WaterTrack]: 'EMS_WATERTRACK',
	[DashboardNames.SewerOps]: 'EMS_SEWER_CREW_DASHBOARD',
	[DashboardNames.WaterOps]: 'EMS_WATEROPS_DASHBOARD',
	[DashboardNames.WaterQuality]: 'EMS_WATERQUALITY_DASHBOARD',
	[DashboardNames.Alert]: 'EMS_ALERT_DASHBOARD'
}

const dashboardToRoute = {
	[DashboardNames.IncidentTracking]: '/',
	[DashboardNames.FloodTrack]: '/flood-dashboard',
	[DashboardNames.WaterTrack]: '/water-dashboard',
	[DashboardNames.SewerOps]: '/sewer-ops-dashboard',
	[DashboardNames.WaterOps]: '/water-ops-dashboard',
	[DashboardNames.Alert]: '/alert-dashboard',
	[DashboardNames.WaterQuality]: '/water-quality-dashboard'
}

/**
 * Get the first authorized dashboard for the user.
 * If the user is coming from an authorized dashboard, redirect to that dashboard.
 * If the user is not authorized for any dashboard, redirect to the auth-error page.
 * @param from - The route the user is coming from
 * @return {string|*} - The path the user should be redirected to
 */
const getFirstAuthorizedDashboard = (from) => {
	const roles = store.getters['user/roles']
	/**
	 * If user is coming from an authorized dashboard, redirect to that dashboard
	 */
	if (from) {
		const fromDashboard = from.name
		const fromDashboardAuthorizedRole = dashboardToRole[fromDashboard]
		if (roles.includes(fromDashboardAuthorizedRole)) {
			return from.path
		}
	}

	/**
	 * Find the first dashboard that is authorized for the user
	 */
	const firstAuthorizedDashboard = Object.keys(dashboardToRole).find((dashboard) => {
		return roles.includes(dashboardToRole[dashboard])
	})

	/**
	 * No dashboard is authorized for the user
	 */
	if (!firstAuthorizedDashboard) {
		return '/auth-error'
	}

	return dashboardToRoute[firstAuthorizedDashboard]
}

const routes = [
	{
		path: '/',
		name: DashboardNames.IncidentTracking,
		component: IncidentTracking,
		meta: {
			requiresAuth: true
		},
		beforeEnter: (to, from, next) => {
			const roles = store.getters['user/roles']
			const authorizedRole = dashboardToRole[DashboardNames.IncidentTracking]

			if (roles && !roles.includes(authorizedRole)) {
				next(getFirstAuthorizedDashboard(from))
			} else {
				store.dispatch('setDashboardDetails', {
					currentDashboard: 'IncidentDashboard',
					storeNamespace: 'incidentTracking'
				})
				next()
			}
		}
	},
	{
		path: '/flood-dashboard',
		name: DashboardNames.FloodTrack,
		component: FloodTrackDashboard,
		meta: {
			requiresAuth: true
		},
		beforeEnter: (to, from, next) => {
			const roles = store.getters['user/roles']
			const authorizedRole = dashboardToRole[DashboardNames.FloodTrack]

			if (roles && !roles.includes(authorizedRole)) {
				next(getFirstAuthorizedDashboard(from))
			} else {
				store.dispatch('setDashboardDetails', {
					currentDashboard: 'FloodDashboard',
					storeNamespace: 'floodTrack'
				})
				next()
			}
		}
	},
	{
		path: '/water-dashboard',
		name: DashboardNames.WaterTrack,
		component: WaterTrackDashboard,
		meta: {
			requiresAuth: true
		},
		beforeEnter: (to, from, next) => {
			const roles = store.getters['user/roles']
			const authorizedRole = dashboardToRole[DashboardNames.WaterTrack]

			if (roles && !roles.includes(authorizedRole)) {
				next(getFirstAuthorizedDashboard(from))
			} else {
				store.dispatch('setDashboardDetails', {
					currentDashboard: 'WaterDashboard',
					storeNamespace: 'waterTrack'
				})
				next()
			}
		}
	},
	{
		path: '/sewer-ops-dashboard',
		name: DashboardNames.SewerOps,
		component: SewerOpsDashboard,
		meta: {
			requiresAuth: true
		},
		beforeEnter: (to, from, next) => {
			const roles = store.getters['user/roles']
			const authorizedRole = dashboardToRole[DashboardNames.SewerOps]

			if (roles && !roles.includes(authorizedRole)) {
				next(getFirstAuthorizedDashboard(from))
			} else {
				store.dispatch('setDashboardDetails', {
					currentDashboard: 'SewerOpsDashboard',
					storeNamespace: 'sewerOps'
				})
				next()
			}
		}
	},
	{
		path: '/water-ops-dashboard',
		name: DashboardNames.WaterOps,
		component: WaterOpsDashboard,
		meta: {
			requiresAuth: true
		},
		beforeEnter: (to, from, next) => {
			const roles = store.getters['user/roles']
			const authorizedRole = dashboardToRole[DashboardNames.WaterOps]

			if (roles && !roles.includes(authorizedRole)) {
				next(getFirstAuthorizedDashboard(from))
			} else {
				store.dispatch('setDashboardDetails', {
					currentDashboard: 'WaterOpsDashboard',
					storeNamespace: 'waterOps'
				})
				next()
			}
		}
	},
	{
		path: '/water-quality-dashboard',
		name: DashboardNames.WaterQuality,
		component: WaterQualityDashboard,
		meta: {
			requiresAuth: true
		},
		beforeEnter: (to, from, next) => {
			const roles = store.getters['user/roles']
			const authorizedRole = dashboardToRole[DashboardNames.WaterQuality]

			if (roles && !roles.includes(authorizedRole)) {
				next(getFirstAuthorizedDashboard(from))
			} else {
				store.dispatch('setDashboardDetails', {
					currentDashboard: 'WaterQualityDashboard',
					storeNamespace: 'waterQuality'
				})
				next()
			}
		}
	},
	{
		path: '/alert-dashboard',
		name: DashboardNames.Alert,
		component: AlertDashboard,
		meta: {
			requiresAuth: true
		},
		beforeEnter: (to, from, next) => {
			const roles = store.getters['user/roles']
			const authorizedRole = dashboardToRole[DashboardNames.Alert]

			if (roles && !roles.includes(authorizedRole)) next(getFirstAuthorizedDashboard(from, roles))
			else {
				store.dispatch('setDashboardDetails', {
					currentDashboard: 'AlertDashboard',
					storeNamespace: 'alertDash'
				})
				next()
			}
		}
	},
	{
		path: '/auth-error',
		name: 'auth-error',
		component: PermissionErrorPage
	},
	{
		path: '/demo-page',
		name: 'demopage',
		component: DemoPage
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
